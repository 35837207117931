import { useEffect, useState } from 'react';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { Grid, Box, Typography, LinearProgress, Button } from '@mui/material';
import { Modal } from '@components/Modal';
import { FormProvider, useForm, DefaultValues, SubmitHandler } from 'react-hook-form';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { Icon } from '@components/Icon';
import { NotificationsResponse } from '@api/admin/adminGeneratedApi';
import { Info } from '@area/notificationsPage/components/Info';
import { EditTargetsModal } from '@area/notificationsPage/components/EditTargetsModal';
import { EmailFormData, validationSchema } from '../validation/validationSchemaEmail';
import styles from './style.module.scss';

interface FormProps {
  defaultValues: DefaultValues<EmailFormData>;
  onSubmit: SubmitHandler<EmailFormData>;
  readonly?: boolean;
  isLoading: boolean;
  notification?: NotificationsResponse;

  onSendEmail?(): void;

  onDelete?(): void;
}

export const EditEmailForm = ({
  defaultValues,
  onSubmit,
  readonly = false,
  isLoading,
  onSendEmail,
  onDelete,
  notification,
}: FormProps) => {
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [editTargetsModalOpen, setEditTargetsModalOpen] = useState(false);
  const resolver = useYupValidationResolver<EmailFormData>(validationSchema);

  const form = useForm<EmailFormData>({
    defaultValues: defaultValues as unknown as EmailFormData,
    resolver,
  });
  const targets = form.watch('targets');
  const handleSubmit = form.handleSubmit(onSubmit);

  const toggleSendModal = () => setSendModalOpen(!sendModalOpen);
  const onSendEmailLocal = async () => {
    if (Object.keys(form.formState.dirtyFields).length) {
      await handleSubmit();
    }

    onSendEmail?.();
  };

  useEffect(() => {
    if (form.formState.isSubmitting) return;
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <Grid item container flexDirection="column" gap={indents.l} className={styles.formWrapper}>
        {/*<Grid item className={styles.logBtnWrapper}>*/}
        {/*  {!isNew && (*/}
        {/*    <Button variant="outlined" className={styles.logBtn} onClick={toggleSendModal}>*/}
        {/*      Лог рассылки*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</Grid>*/}
        <FormProvider {...form}>
          {/*<Grid item>*/}
          {/*  <FormField*/}
          {/*    name="date"*/}
          {/*    type="dateTimePicker"*/}
          {/*    title="Дата отправки"*/}
          {/*    sx={{ width: 200 }}*/}
          {/*    disabled={readonly}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item>
            <FormField name="title" title="Заголовок письма" fullWidth disabled={readonly} />
          </Grid>
          <Grid item>
            <Info notification={notification} />
          </Grid>
          <Grid>
            <FormField name="text" title="Текст" type="wysiwyg" />
          </Grid>
          <Grid item flex={1}>
            <Typography variant="h2" marginBottom="12px">
              Всего адресов: {targets ? targets.split(',').length : 0}
            </Typography>
            <Button variant="contained" onClick={() => setEditTargetsModalOpen(true)}>
              {readonly ? 'Просмотр' : 'Изменить'}
            </Button>
          </Grid>
          <Grid item>
            <Grid item>
              <FormField
                name="from"
                type="select"
                placeholder="Выберите отправителя"
                title="Отправитель"
                disabled={readonly}
                sx={{ maxWidth: 360 }}
                options={[
                  {
                    label: 'campus@studgorod.ru',
                    value: 'campus@studgorod.ru',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </FormProvider>
        {isLoading && <LinearProgress className={styles.loadingIndicator} />}
      </Grid>
      {!readonly && (
        <Box className={styles.submitBtnWrapper}>
          <Button
            disabled={readonly || form.formState.isSubmitting}
            variant="contained"
            className={styles.submitBtn}
            startIcon={<Icon name="logoIcon" className={styles.submitBtnIcon} />}
            onClick={handleSubmit}>
            Сохранить
          </Button>
          {onSendEmail && (
            <Button
              disabled={form.formState.isSubmitting}
              variant="contained"
              className={styles.sendBtn}
              startIcon={<Icon name="send" className={styles.submitBtnIcon} />}
              onClick={onSendEmailLocal}>
              Отправить
            </Button>
          )}
          {onDelete && (
            <Button
              disabled={form.formState.isSubmitting}
              variant="outlined"
              className={styles.delBtn}
              startIcon={<Icon name="delete" className={styles.submitDelIcon} />}
              onClick={onDelete}>
              Удалить
            </Button>
          )}
        </Box>
      )}
      <EditTargetsModal
        type="email"
        readonly={readonly}
        targets={targets}
        open={editTargetsModalOpen}
        onClose={() => setEditTargetsModalOpen(false)}
        onSave={(value) => form.setValue('targets', value)}
      />
      <Modal
        open={sendModalOpen}
        title="Отправка сообщения"
        maxWidth="sm"
        onClose={toggleSendModal}
        actions={
          <Button variant="contained" onClick={toggleSendModal} className={styles.closeModalBtn}>
            Закрыть
          </Button>
        }>
        <Grid container gap={indents.s}>
          <Grid item xs={12}>
            <Typography variant="body2">Собираем HTML письма.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Письмо собралось.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">Создаем сообщение.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запускается....</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Рассылка запущена.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>Отправлено: 1 пользователю..</Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
