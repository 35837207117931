import { NotificationsResponse } from '@api/admin/adminGeneratedApi';
import { Link, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

export interface InfoProps {
  notification: NotificationsResponse | undefined;
}

export const Info = ({ notification }: InfoProps) => {
  return (
    <Stack direction="row" gap="48px">
      <div>
        <Typography variant="h2">Номер задания</Typography>
        <Typography fontWeight={400} fontSize="14px" marginTop="7px">
          {notification?.trackId || 'не отправлено'}
        </Typography>
      </div>
      {notification?.trackId && (
        <div>
          <Typography variant="h2">
            ID рассылки{' '}
            {notification?.sendsayId && (
              <Link
                href={`https://app.sendsay.ru/reports/campaigns/${notification?.sendsayId}/summary`}
                target="_blank">
                (открыть)
              </Link>
            )}
          </Typography>
          <Typography fontWeight={400} fontSize="14px" marginTop="7px">
            {notification?.sendsayId || 'неизвестно'}
          </Typography>
        </div>
      )}
    </Stack>
  );
};
