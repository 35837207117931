import { Button, Grid, Paper } from '@mui/material';
import { Table } from '@components/Table/Table';
import { FooterLinkResponse } from '@api/admin/adminGeneratedApi';
import { Header } from '@layouts/Base/components/Header';
import indents from '@styles/indents.module.scss';
import { useFooterLinks } from '@area/footerLinks/hooks/useFooterLinks';
import { EditModal } from '@area/footerLinks/components/EditModal';

export const FooterLinks = () => {
  const { modalRef, links, columns } = useFooterLinks();

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item>
        <Header
          title="Подвальные ссылки"
          actions={
            <Button onClick={() => modalRef.current?.open()} variant="contained">
              Новая ссылка
            </Button>
          }
        />
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Paper sx={{ padding: '20px' }}>
          <Table<FooterLinkResponse>
            columns={columns}
            data={links.data ?? []}
            loading={links.isLoading}
            fetched={!links?.data?.length && (links.isError || links.isSuccess)}
            tableLayout="fixed"
          />
        </Paper>
      </Grid>
      <EditModal modalRef={modalRef} refetch={links.refetch} />
    </Grid>
  );
};
