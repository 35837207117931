import { useRef, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { Routes } from '@navigation/routes';
import { NotificationTypeName } from '@area/notificationsPage/types';
import { Icon } from '@components/Icon';
import { ThemeProvider } from '@mui/material/styles';
import { innerTheme } from '@pages/Notifications/theme';

export const CreateButton = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const goToNewNotificationPage = (notificationType: NotificationTypeName) => {
    navigate(`${notificationType}/${Routes.newItem}`);
  };

  return (
    <div ref={wrapperRef}>
      <Button onClick={open} variant="contained">
        Новая рассылка
      </Button>
      <ThemeProvider theme={innerTheme}>
        <Menu
          disablePortal
          disableAutoFocus
          disableAutoFocusItem
          open={isOpen}
          anchorEl={wrapperRef.current}
          PaperProps={{ style: { minWidth: wrapperRef.current?.clientWidth, maxWidth: 'fit-content' } }}
          onClose={close}>
          <MenuItem disableRipple value="email" onClick={() => goToNewNotificationPage('email')}>
            <Box component="span" sx={{ marginRight: '8px', display: 'inline-flex' }}>
              <Icon name="email" size={20} />
            </Box>
            <Typography variant="body2" noWrap>
              Email
            </Typography>
          </MenuItem>
          <MenuItem disableRipple value="push" onClick={() => goToNewNotificationPage('push')}>
            <Box component="span" sx={{ marginRight: '8px', display: 'inline-flex' }}>
              <Icon name="send" size={20} />
            </Box>
            <Typography variant="body2" noWrap>
              Push
            </Typography>
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </div>
  );
};
