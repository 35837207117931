import { createTheme, Theme } from '@mui/material';
import colors from '@styles/colors.module.scss';
import indents from '@styles/indents.module.scss';
import shadows from '@styles/shadows.module.scss';

const MENU_ITEM_HEIGHT = 36;

export const innerTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: shadows.card,
            marginTop: 2,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            maxHeight: MENU_ITEM_HEIGHT * 5 + 12, // 12 - удвоенный размер паддинга (6px)
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: indents.xxxs,
            paddingBottom: indents.xxxs,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            height: MENU_ITEM_HEIGHT,
            padding: `${indents.xxxs} ${indents.xs}`,
            transition: 'background-color 0.124s ease-in',
            '&:hover': {
              backgroundColor: colors.background,
            },
            '&.Mui-selected': {
              backgroundColor: colors.lightRed,
              '&:hover': {
                backgroundColor: colors.lightRed,
              },
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  });
