import {
  useNotificationsControllerGetByIdQuery,
  useNotificationsControllerUpdateMutation,
} from '@api/admin/adminGeneratedApi';
import { PushFormData } from '@area/notificationsPage/validation/validationSchemaPush';
import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import indents from '@styles/indents.module.scss';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Header } from '@layouts/Base/components/Header';
import { EditPushForm } from '@area/notificationsPage/components/EditPushForm';
import { usePageTitle } from '@hooks/usePageTitle';
import { DefaultValues, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import {
  useNotificationsControllerDeleteMutation,
  useNotificationsControllerSendEmailMutation,
} from '@api/admin/adminApi';
import { useState } from 'react';
import { ConfirmModal } from '@pages/EditEmailPage/ConfirmModal';
import styles from './style.module.scss';

export const EditPushPage = () => {
  usePageTitle('Push-рассылка');
  const { id } = useParams();
  const { data: notificationMailing, refetch } = useNotificationsControllerGetByIdQuery({
    id: id ?? '',
  });
  const defaultValues: DefaultValues<PushFormData> = {
    title: notificationMailing?.title ?? '',
    text: notificationMailing?.text ?? '',
    link: notificationMailing?.link ?? '',
    targets: notificationMailing?.targets?.join(',') ?? '',
  };
  const [confirmAction, setConfirmAction] = useState<'send' | 'delete' | null>(null);
  const notificationsHelpers = useNotifications();

  const [updateNotificationMailing, { isLoading }] = useNotificationsControllerUpdateMutation();
  const [sendEmailController, { isLoading: isLoadingSend }] = useNotificationsControllerSendEmailMutation();
  const [deleteEmailController, { isLoading: isLoadingDelete }] = useNotificationsControllerDeleteMutation();

  const onEditPush: SubmitHandler<PushFormData> = async (formData) => {
    await updateNotificationMailing({
      id: id ?? '',
      notificationsRequest: {
        type: 3,
        sendAt: undefined,
        title: formData.title,
        text: formData.text,
        targets: formData.targets.split(','),
        link: formData.link,
      },
    }).unwrap();
    await refetch();
    notificationsHelpers.handlePushItem({
      text: 'Рассылка обновлена',
      severity: 'success',
    });
  };
  const onSendPush = () => setConfirmAction('send');
  const onDeletePush = () => setConfirmAction('delete');

  const apply = async () => {
    if (confirmAction === 'send') {
      setConfirmAction(null);
      await sendEmailController({ id: id! }).unwrap();
      await refetch();
      notificationsHelpers.handlePushItem({
        text: 'Успешно поставлено в очередь на отправку',
        severity: 'success',
      });
    }

    if (confirmAction === 'delete') {
      setConfirmAction(null);
      await deleteEmailController({ id: id! }).unwrap();
      // navigate(buildRoutePath(Routes.notifications, { absolute: true }));
      notificationsHelpers.handlePushItem({
        text: 'Рассылка удалена',
        severity: 'success',
      });
    }
  };

  if (!notificationMailing) {
    return null;
  }

  const readonly =
    notificationMailing &&
    (notificationMailing?.status > 1 ||
      (!!notificationMailing?.sendAt && new Date(notificationMailing?.sendAt).getTime() <= new Date().getTime()));

  return (
    <Grid container flexDirection="column" className={styles.root} gap={indents.m}>
      <Grid item>
        <Header
          title="Push-рассылка"
          backBtnTitle="Назад к списку рассылок"
          backBtnRoute={buildRoutePath(Routes.notifications, { absolute: true })}
        />
      </Grid>
      <EditPushForm
        readonly={readonly}
        defaultValues={defaultValues}
        isLoading={isLoading}
        notification={notificationMailing}
        onSubmit={onEditPush}
        onSend={readonly ? undefined : onSendPush}
        onDelete={readonly ? undefined : onDeletePush}
      />
      {!!confirmAction && (
        <ConfirmModal
          applyText={confirmAction === 'send' ? 'Отправить' : 'Удалить'}
          onApply={apply}
          onClose={() => setConfirmAction(null)}
          title={confirmAction === 'send' ? 'Подтверждение отправки' : 'Подтверждение удаления'}
        />
      )}
    </Grid>
  );
};
