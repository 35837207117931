import { FooterLinkResponse } from '@api/admin/adminGeneratedApi';
import { IconButton } from '@mui/material';
import styles from '@area/footerLinks/styles.module.scss';
import { Icon } from '@components/Icon';
import colors from '@styles/colors.module.scss';
import { Stack } from '@mui/system';
import { RefObject, useState } from 'react';
import { EditModalHandlers } from '@area/footerLinks/components/EditModal/types';
import { useFooterLinksControllerDeleteMutation } from '@api/admin/adminApi';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { ConfirmDeleteModal } from '@area/footerLinks/components/ConfirmDeleteModal';

export interface ActionsCellProps {
  link: FooterLinkResponse;
  modalRef: RefObject<EditModalHandlers>;
}

export const ActionsCell = ({ link, modalRef }: ActionsCellProps) => {
  const { handlePushItem } = useNotifications();
  const [deleteLink, { isLoading: isDeleting }] = useFooterLinksControllerDeleteMutation();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const onEditClick = () => modalRef.current?.open(link);
  const onDeleteClick = async () => {
    try {
      await deleteLink({ id: link.id }).unwrap();

      handlePushItem({ severity: 'success', text: `Ссылка успешно удалена` });
    } catch (e) {
      handlePushItem({ severity: 'error', text: `Не удалось удалить ссылку` });
    }
  };

  return (
    <>
      <Stack gap={1} direction="row">
        <IconButton className={styles.iconButton} onClick={onEditClick} disabled={isDeleting}>
          <Icon name="edit" color={colors.black} />
        </IconButton>
        <IconButton className={styles.iconButton} onClick={() => setIsOpenDeleteModal(true)} disabled={isDeleting}>
          <Icon name="delete" color={colors.red} />
        </IconButton>
      </Stack>
      <ConfirmDeleteModal
        open={isOpenDeleteModal}
        deleting={isDeleting}
        onClose={() => setIsOpenDeleteModal(false)}
        onConfirm={onDeleteClick}
      />
    </>
  );
};
