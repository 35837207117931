import {
  MediaFileUpdaterDto,
  useFilesControllerDeleteFileMutation,
  useFilesControllerEditFileDescriptionsMutation,
} from '@api/admin/adminGeneratedApi';
import { MediaFileParams } from '@area/publications/defaultValues';
import { useDispatch } from 'react-redux';
import { incrementUploadedFilesNumber, setFilesNumber, setSnackbarOpen } from '@area/mediaFilesUploadSnackbar/store';
import { useFilesControllerUploadFileMutation } from '@api/admin/adminApi';

interface UploadMediaFilesParams {
  subjectId: string;
  mainImage?: MediaFileParams;
  snippetPhoto?: MediaFileParams;
  photoGallery?: MediaFileParams[];
}

export enum FileType { // TODO заменить на enum из сгенерированных типов
  userAvatar = 1,
  mainImage, // главно изображение
  photoGallery, // фото репортаж
  video,
  snippet, // фото для снипета
  editorPhoto, // фотография заинлайненная в редакторе
}

export const useUploadMediaFiles = () => {
  const dispatch = useDispatch();
  const [upload, { error: uploadError }] = useFilesControllerUploadFileMutation();
  const [deleteFile, { error: deleteError }] = useFilesControllerDeleteFileMutation();
  const [updateFileDescription] = useFilesControllerEditFileDescriptionsMutation();

  const createFormDataFromFile = (mediaFile: MediaFileUpdaterDto) => {
    const formData = new FormData();
    formData.set('file', mediaFile.file);
    formData.set('type', mediaFile.type.toString());
    if (mediaFile.alt) formData.set('alt', mediaFile.alt);
    if (mediaFile.metadata) formData.set('metadata', mediaFile.metadata);
    return formData as any;
  };

  const countNumberOfFiles = ({ mainImage, snippetPhoto, photoGallery }: Omit<UploadMediaFilesParams, 'subjectId'>) => {
    let numberOfFiles = 0;
    if (mainImage?.file) numberOfFiles++;
    if (snippetPhoto?.file) numberOfFiles++;
    photoGallery?.forEach((item) => {
      if (item?.file) numberOfFiles++;
    });
    if (numberOfFiles > 0) {
      dispatch(setFilesNumber(numberOfFiles));
      dispatch(setSnackbarOpen(true));
    }
  };

  const uploadFile = async (subjectId: string, type: FileType, file?: MediaFileParams) => {
    if (file?.file) {
      if (file.id) {
        await deleteFile({ fileId: file.id });
      }
      await upload({
        subjectId,
        mediaFileUpdaterDto: createFormDataFromFile({ type, ...file }),
      });
      dispatch(incrementUploadedFilesNumber());
    } else if (file?.id) {
      updateFileDescription({
        fileId: file.id,
        mediaFileEditDto: { alt: file.alt ?? '', metadata: file.metadata ?? '' },
      });
    }
  };

  const uploadMediaFiles = async ({ subjectId, mainImage, snippetPhoto, photoGallery }: UploadMediaFilesParams) => {
    countNumberOfFiles({ mainImage, snippetPhoto, photoGallery });
    await uploadFile(subjectId, FileType.mainImage, mainImage);
    await uploadFile(subjectId, FileType.snippet, snippetPhoto);

    if (photoGallery)
      await Promise.all(
        photoGallery?.map((item) => {
          return uploadFile(subjectId, FileType.photoGallery, item);
        }),
      );
  };
  return { uploadMediaFiles, uploadError, deleteError };
};
