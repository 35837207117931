import { useState } from 'react';
import { useParams } from 'react-router';
import { Button, FormControlLabel, Typography } from '@mui/material';
import { usePaymentControllerCreatePaymentMutation } from '@api/admin/adminApi';
import { Modal } from '@components/Modal';
import { DateService } from '@services/DateService';
import { PaymentType } from '@area/payments/types';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { Select } from '@components/Select';
import { Option } from '@components/Select/types';
import { studentGrades } from '@services/grades';
import { Checkbox } from '@components/Checkbox';

export interface CreatePaymentButtonProps {
  onCreate?: () => void;
}

const periods = studentGrades.map((item): Option => ({ label: item, value: item }));

export const CreatePaymentButton = ({ onCreate }: CreatePaymentButtonProps) => {
  const { paymentType } = useParams<{ paymentType: PaymentType }>();
  const [openModal, setOpenModal] = useState(false);
  const [create, { isLoading }] = usePaymentControllerCreatePaymentMutation();
  const { handlePushItem } = useNotifications();
  const [selectedPeriod, setSelectedPeriod] = useState<Option>(periods[0]);
  const [onlyNewParticipants, setOnlyNewParticipants] = useState(false);

  const onLocalCreate = async () => {
    if (!paymentType) return;
    await create({ paymentType, paymentCreateRequest: { period: selectedPeriod.value, onlyNewParticipants } });
    onCreate?.();
    setOpenModal(false);
    handlePushItem({ severity: 'success', text: 'Выплата успешно создана' });
  };
  const onClose = () => {
    if (isLoading) return;

    setOpenModal(false);
  };

  return (
    <>
      <Button sx={{ mr: 2 }} onClick={() => setOpenModal(true)} variant="contained">
        Новая выплата
      </Button>
      <Modal
        open={openModal}
        title="Новая выплата"
        maxWidth="xs"
        onClose={onClose}
        actions={
          <>
            <Button onClick={onLocalCreate} variant="contained" disabled={isLoading}>
              Сформировать
            </Button>
            <Button onClick={onClose} variant="outlined" disabled={isLoading}>
              Отмена
            </Button>
          </>
        }>
        <Typography variant="body2">
          Сформировать новую выплату от {DateService.getLocalDate(new Date(), DateService.dateFormats.date)}?
        </Typography>
        <div>
          <Select value={selectedPeriod} onChange={(option) => setSelectedPeriod(option as Option)} options={periods} />
          <FormControlLabel
            sx={{ marginTop: '16px' }}
            control={
              <Checkbox checked={onlyNewParticipants} onChange={() => setOnlyNewParticipants((prev) => !prev)} />
            }
            label="Учитывать только новых участников"
          />
        </div>
      </Modal>
    </>
  );
};
