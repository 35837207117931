import { NotificationsFiltersRequest, NotificationsShortResponse } from '@api/admin/adminGeneratedApi';
import { useNotificationsControllerDeleteMutation } from '@api/admin/adminApi';
import { useNotificationsFilter } from '@area/notifications/hooks/useNotificationsFilter';
import { DEFAULT_PUBLICATIONS_SIZE } from '@area/publications/defaultValues';
import { Option } from '@components/Select/types';
import { useQueryParams } from '@hooks/useQueryParams';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Tooltip, IconButton, Paper, Stack, Grid, Typography } from '@mui/material';
import { useNavigate, useOutlet } from 'react-router-dom';
import { Icon } from '@components/Icon';
import { Table } from '@components/Table/Table';
import { Column } from '@components/Table/types';
import { Filter } from '@components/Filter';
import colors from '@styles/colors.module.scss';
import { usePageTitle } from '@hooks/usePageTitle';
import { NotificationType } from '@area/notificationsPage/types';
import { Header } from '@layouts/Base/components/Header';
import { DateService } from '@services/DateService';
import { CreateButton } from './CreateButton';
import styles from './styles.module.scss';

export const NotificationsPage = () => {
  const [data, setData] = useState<NotificationsShortResponse[]>([]);
  const outlet = useOutlet();
  const navigate = useNavigate();

  usePageTitle('Рассылки и уведомления');

  const { getNotifications, notificationsList, paginate, isLoading, isSuccess, originalArgs, getValuesFromParams } =
    useNotificationsFilter();

  const { params } = useQueryParams();

  const [deleteNotificationMailing] = useNotificationsControllerDeleteMutation();

  const fetchData = (
    values: Partial<NotificationsFiltersRequest & { date?: { startDate?: string; endDate?: string } }>,
    skip: number,
    size = DEFAULT_PUBLICATIONS_SIZE,
  ) => {
    getNotifications({
      skip,
      size,
      asc: false,
      notificationsFiltersRequest: {
        search: values.search,
        type: (values.type as unknown as Option<number>)?.value,
      },
    });
  };

  const onChange = (
    values: Partial<NotificationsFiltersRequest & { date?: { startDate?: string; endDate?: string } }>,
  ) => {
    setData([]);
    fetchData(values, 0);
  };

  const loadMore = () => {
    fetchData(originalArgs?.notificationsFiltersRequest!, data.length);
  };

  const refresh = () => {
    fetchData(originalArgs?.notificationsFiltersRequest!, 0);
  };

  const onClickDelete = async (id: string) => {
    await deleteNotificationMailing({
      id,
    });
    refresh();
  };

  const columns: Column<NotificationsShortResponse>[] = useMemo(
    () => [
      {
        title: 'Статус',
        align: 'center',
        dataIndex: 'status',
        key: 'status',
        render: (value: number, record: NotificationsShortResponse) => (
          <Tooltip title={value ? 'Отправлено' : 'Не отправлено'} arrow>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Icon name={value !== 1 ? 'check' : 'close'} htmlColor={value !== 1 ? colors.success : colors.error} />
            </Box>
          </Tooltip>
        ),
      },
      {
        title: 'Дата отправки',
        dataIndex: 'sendAt',
        key: 'sendAt',
        render: (value: Date) => (
          <Typography className={styles.date} variant="body2">
            {value ? DateService.getLocalDate(value) : 'Не установлена'}
          </Typography>
        ),
      },
      {
        title: 'Тип',
        align: 'center',
        dataIndex: 'type',
        key: 'type',
        render: (value: number) => {
          return (
            <Tooltip title={value === NotificationType.email ? 'Email' : 'Push'} arrow>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Icon name={value === NotificationType.email ? 'email' : 'notification'} htmlColor={colors.grey} />
              </Box>
            </Tooltip>
          );
        },
      },
      {
        title: 'Название',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Номер задания',
        dataIndex: 'trackId',
        key: 'trackId',
        render: (value: string) => value ?? '-',
      },
      {
        title: 'ID рассылки',
        dataIndex: 'sendsayId',
        key: 'sendsayId',
        render: (value: string) => value ?? '-',
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (_: string[], record?: NotificationsShortResponse) => (
          <Stack direction="row" gap="6px" className={styles.actions}>
            {record?.type === 3 && (
              <IconButton className={styles.iconButton} onClick={() => onClickDelete(record?.id ?? '')}>
                <Icon name="delete" />
              </IconButton>
            )}
          </Stack>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (!outlet) {
      const values = getValuesFromParams(params);

      fetchData(values, data.length);
    }
  }, [outlet]);

  useEffect(() => {
    if (isSuccess) {
      setData([...data, ...(notificationsList || [])]);
    }
  }, [isSuccess]);

  if (outlet) {
    return outlet;
  }

  return (
    <Grid container direction="column" gap="24px">
      <Grid item>
        <Header title="Рассылки и уведомления" actions={<CreateButton />} />
      </Grid>

      <Grid item>
        <Filter<NotificationsFiltersRequest>
          defaultValues={{ search: '' }}
          onChange={onChange}
          searchField={{
            type: 'text',
            name: 'search',
            props: {
              placeholder: 'Поиск',
              sx: { minWidth: '200px', background: colors.white },
            },
            stretchable: true,
          }}
        />
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Paper className={styles.paper}>
          <Table<NotificationsShortResponse>
            columns={columns}
            loading={isLoading}
            data={data}
            onRowClick={(record) => {
              navigate(
                buildRoutePath(
                  [Routes.notifications, record.type === 1 ? Routes.email : Routes.push, record.id as any],
                  { absolute: true },
                ),
              );
            }}
          />
          {!!paginate?.totalCount && data?.length < paginate?.totalCount && (
            <Button
              onClick={loadMore}
              variant="outlined"
              startIcon={<Icon name="more" />}
              className={styles.loadMoreBtn}
              disabled={isLoading}>
              Загрузить еще
            </Button>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
