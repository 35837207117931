import { Button, Grid, Typography } from '@mui/material';
import indents from '@styles/indents.module.scss';
import { Modal } from '@components/Modal';
import Stack from '@mui/material/Stack';
import { Input } from '@components/Input';
import { useEffect, useState } from 'react';

export interface EditTargetsModalProps {
  open: boolean;
  readonly: boolean;
  targets: string;
  onClose: () => void;
  onSave: (targets: string) => void;
  type: 'pushapp' | 'email' | 'msisdn';
}

export const EditTargetsModal = ({ targets, readonly, open, onClose, onSave, type }: EditTargetsModalProps) => {
  const [value, setValue] = useState('');

  const save = () => {
    onSave(
      value
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean)
        .join(','),
    );
    onClose();
  };

  useEffect(() => {
    if (!open || !targets.length) return;
    setValue(targets.split(',').join(',\n'));
  }, [open, targets]);

  return (
    <Modal
      open={open}
      title="Редактирования списка участников рассылки"
      maxWidth="sm"
      onClose={onClose}
      actions={
        <Stack direction="row" gap={indents.s}>
          <Button variant="outlined" onClick={onClose}>
            {readonly ? 'Закрыть' : 'Отмена'}
          </Button>
          {!readonly && (
            <Button variant="contained" onClick={save}>
              Сохранить
            </Button>
          )}
        </Stack>
      }>
      <Grid container gap={indents.s}>
        <Grid item xs={12}>
          <Typography variant="h2" marginBottom="12px">
            Кому, через запятую
          </Typography>
          <Input
            disabled={readonly}
            value={value}
            onChange={({ target }) => setValue(target.value)}
            fullWidth
            multiline
            minRows={10}
            maxRows={10}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Доступные группы:</Typography>
          <Typography fontWeight={400} marginTop="6px">
            all_{type} - Все пользователи <br />
            esk_card_{type} - Владельцы карт ЕСК <br />
            extra_grant_{type} - Подавшие заявку на доп стипендию <br />
            credit_grant_{type} - Подавшие заявку на компенсацию кредита <br />
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};
