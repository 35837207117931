import { Navigate, useRoutes } from 'react-router';
import { LoginLayout } from '@layouts/Login';
import { BaseLayout } from '@layouts/Base';
import { KampusEvents } from '@pages/KampusEvents';
import { EditKampusEvent } from '@pages/EditKampusEvent';
import { NotificationsPage } from '@pages/Notifications';
import { NewEmailPage } from '@pages/NewEmailPage';
import { EditEmailPage } from '@pages/EditEmailPage';
import { NewPushPage } from '@pages/NewPushPage';
import { EditPushPage } from '@pages/EditPushPage';
import { LoginPage } from '@pages/Login';
import { OtpPage } from '@pages/Otp';
import { ConfirmPage } from '@pages/Confirm';
import { PublicationsPage } from '@pages/Publications';
import { NewPublicationPage } from '@pages/NewPublication';
import { EditPublicationPage } from '@pages/EditPublication';
import { InfoPages } from '@pages/InfoPages';
import { NewInfoPage } from '@pages/NewInfoPage';
import { EditInfoPage } from '@pages/EditInfoPage';
import { TranslationsPage } from '@pages/Translations';
import { SettingsPage } from '@pages/Settings';
import { ScholarshipsPage } from '@pages/Scholarships';
import { ProfilePage } from '@pages/Profile';
import { NotFoundPage } from '@pages/404';
import { StudentsPage } from '@pages/Students';
import { StudentPage } from '@pages/Student';
import { ApplicationsPage } from '@pages/Applications';
import { ApplicationPage } from '@pages/Application';
import { ApplicationLoan } from '@pages/ApplicationLoan';
import { PartnersPage } from '@pages/Partners';
import { EditPartners } from '@pages/EditPartners';
import { TicketsPage } from '@pages/Tickets';
import { TicketPage } from '@pages/Ticket';
import { NewTicket } from '@pages/NewTicket';
import { UsersPage } from '@pages/Users';
import { NewUserPage } from '@pages/NewUser';
import { EditUserPage } from '@pages/EditUser';
import { NsiLogs } from '@pages/NsiLogs';
import { NsiLog } from '@pages/NsiLog';
import { FooterLinksPage } from '@pages/FooterLinks';
import { OnboardingChangePasswordPage } from 'pages/OnboardingChangePassword';
import { PaymentPage } from 'pages/Payment';
import { PaymentsPage } from 'pages/Payments';
import { Routes } from './routes';

export const Content = () => {
  return useRoutes([
    {
      path: Routes.main,
      element: <BaseLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={Routes.students} />,
        },
        // Управление ИС
        {
          path: Routes.users,
          element: <UsersPage />,
          children: [
            {
              path: Routes.newItem,
              element: <NewUserPage />,
            },
            {
              path: Routes.id,
              element: <EditUserPage />,
            },
          ],
        },
        {
          path: Routes.translations,
          element: <TranslationsPage />,
        },
        {
          path: Routes.settings,
          element: <SettingsPage />,
        },
        {
          path: Routes.scholarships,
          element: <ScholarshipsPage />,
        },
        {
          path: Routes.footerLinks,
          element: <FooterLinksPage />,
        },
        {
          path: Routes.nsiLogs,
          element: <NsiLogs />,
          children: [
            {
              path: Routes.id,
              element: <NsiLog />,
            },
          ],
        },
        // Контент
        {
          path: Routes.publications,
          element: <PublicationsPage />,
          children: [
            {
              path: Routes.newItem,
              element: <NewPublicationPage />,
            },
            {
              path: Routes.id,
              element: <EditPublicationPage />,
            },
          ],
        },
        {
          path: Routes.events,
          element: <KampusEvents />,
          children: [
            {
              path: Routes.newItem,
              element: <EditKampusEvent />,
            },
            {
              path: Routes.id,
              element: <EditKampusEvent />,
            },
          ],
        },
        {
          path: Routes.informationPages,
          element: <InfoPages />,
          children: [
            {
              path: Routes.newItem,
              element: <NewInfoPage />,
            },
            {
              path: Routes.id,
              element: <EditInfoPage />,
            },
          ],
        },
        {
          path: Routes.notifications,
          element: <NotificationsPage />,
          children: [
            {
              path: Routes.email,
              children: [
                { path: Routes.newItem, element: <NewEmailPage /> },
                { path: Routes.id, element: <EditEmailPage /> },
              ],
            },
            {
              path: Routes.push,
              children: [
                { path: Routes.newItem, element: <NewPushPage /> },
                { path: Routes.id, element: <EditPushPage /> },
              ],
            },
          ],
        },
        // Заявки
        {
          path: Routes.applications,
          element: <ApplicationsPage />,
          children: [
            { path: `${Routes.applicationType}/${Routes.id}`, element: <ApplicationPage /> }, //
          ],
        },
        {
          path: Routes.applications,
          element: <ApplicationsPage />,
          children: [
            { path: `credit_grant/${Routes.id}`, element: <ApplicationLoan /> }, //
          ],
        },
        // Участники
        {
          path: Routes.students,
          element: <StudentsPage />,
          children: [{ path: Routes.id, element: <StudentPage /> }],
        },
        // Партнеры
        {
          path: Routes.partners,
          element: <PartnersPage />,
          children: [
            {
              path: Routes.newItem,
              element: <EditPartners />,
            },
            {
              path: Routes.id,
              element: <EditPartners />,
            },
          ],
        },
        {
          path: Routes.events,
          element: <KampusEvents />,
          children: [
            {
              path: Routes.newItem,
              element: <EditKampusEvent />,
            },
            {
              path: Routes.id,
              element: <EditKampusEvent />,
            },
          ],
        },
        // Профиль
        {
          path: Routes.profile,
          element: <ProfilePage />,
        },
        // Программы
        {
          path: Routes.payments,
          children: [
            {
              path: Routes.paymentsType,
              element: <PaymentsPage />,
              children: [{ path: Routes.id, element: <PaymentPage /> }],
            },
          ],
        },
        {
          path: Routes.tickets,
          element: <TicketsPage />,
          children: [
            { path: Routes.id, element: <TicketPage /> },
            {
              path: Routes.newItem,
              element: <NewTicket />,
            },
          ],
        },
      ],
    },
    {
      path: Routes.onboardingChangePassword,
      element: <OnboardingChangePasswordPage />,
    },
    {
      path: Routes.login,
      element: <LoginLayout />,
      children: [
        {
          index: true,
          element: <LoginPage />,
        },
        {
          path: Routes.otp,
          element: <OtpPage />,
        },
        {
          path: Routes.confirm,
          element: <ConfirmPage />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);
};
