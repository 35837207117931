import { Filter } from '@components/Filter';
import { FC, ReactNode } from 'react';
import colors from '@styles/colors.module.scss';
import { FilterFormValues } from '@area/studentsPage/defaultValues';
import { useGetEduOrgs } from '@area/payments/hooks/useGetEduOrgs';
import { useGetPrograms } from '@area/payments/hooks/useGetPrograms';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { typeOptions } from '@area/applicationsPage/defaultValues';
import { Field } from '@components/Filter/types';

interface Props {
  onChange: (values: FilterFormValues) => void;
  filter: string[];
  hiddenFilterButtons: ReactNode;
}

export const StudentsFilter: FC<Props> = ({ onChange, filter, hiddenFilterButtons }) => {
  const { data: eduOrgs, isLoading } = useGetEduOrgs();
  const { data: programs, isLoading: isLoadingProgram } = useGetPrograms();
  const eduOrgsOptions = eduOrgs?.map(({ id, nameShort }) => ({ label: nameShort, value: id }));
  const programsOptions = programs?.map(({ id, okso, name }) => ({
    label: `${okso} ${name}`,
    value: id,
    inputLabel: okso,
  }));

  const trackedFields: Field[] = [
    {
      type: 'select',
      name: 'status',
      props: {
        placeholder: 'Статус',
        options: [
          {
            value: 'active',
            label: 'Активный',
          },
          {
            value: 'notActive',
            label: 'Неактивный',
          },
          {
            value: 'activeStudent',
            label: 'Активный студент',
          },
          {
            value: 'activeNotStudent',
            label: 'Активный не студент',
          },
        ],
        sx: { width: '280px', background: colors.white },
      },
    },
    {
      type: 'select',
      name: 'eduOrgs',
      props: {
        multiple: true,
        options: eduOrgsOptions,
        placeholder: 'ВУЗ',
        sx: { width: '280px', background: colors.white },
      },
    },
    {
      type: 'select',
      name: 'unconfirmedByEduOrgs',
      props: {
        multiple: true,
        options: eduOrgsOptions,
        placeholder: 'Неподтвержденный ВУЗ',
        sx: { width: '280px', background: colors.white },
      },
    },
    {
      type: 'select',
      name: 'courseNumber',
      props: {
        multiple: true,
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          { value: 6, label: '6' },
        ],
        placeholder: 'Курс',
        sx: { width: '280px', background: colors.white },
      },
    },
    {
      type: 'select',
      name: 'educationalPrograms',
      props: {
        multiple: true,
        options: programsOptions,
        placeholder: 'Специальность',
        sx: { width: '280px', background: colors.white },
      },
    },
    {
      type: 'select',
      name: 'applicationType',
      props: {
        options: typeOptions,
        placeholder: 'Программа',
        sx: { width: '280px', background: colors.white },
      },
    },
    {
      type: 'datepicker',
      name: 'registrationDateTo',
      props: {
        placeholder: 'Зарегистрирован ДО',
        textFieldProps: { placeholder: 'Зарегистрирован ДО', sx: { width: '280px', background: colors.white } },
      },
    },
    {
      type: 'datepicker',
      name: 'registrationDateFrom',
      props: {
        placeholder: 'Зарегистрирован ПОСЛЕ',
        textFieldProps: {
          placeholder: 'Зарегистрирован ПОСЛЕ',
          sx: { width: '280px', background: colors.white },
        },
      },
    },
  ];

  const filteredTrackedField = trackedFields.filter((el) => filter.includes(el.name));

  if (isLoading || isLoadingProgram) {
    return <LoadingSpinner />;
  }

  return (
    <Filter<FilterFormValues>
      onChange={onChange}
      searchField={{
        type: 'text',
        name: 'search',
        props: {
          placeholder: 'Поиск',
          sx: { minWidth: '300px', background: colors.white },
        },
      }}
      clearable={false}
      trackedFields={filteredTrackedField}
      hiddenFilterButton={hiddenFilterButtons}
      filter={filter}
    />
  );
};
