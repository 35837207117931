import { FC } from 'react';
import { Modal } from '@components/Modal';
import { Button, Grid, Typography } from '@mui/material';
import indents from '@styles/indents.module.scss';

interface Props {
  open: boolean;
  deleting: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmDeleteModal: FC<Props> = ({ open, deleting, onClose, onConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Удаление ссылки"
      fullWidth={false}
      actions={
        <Grid container flexDirection="row" gap={indents.s}>
          <Grid item>
            <Button onClick={onConfirm} variant="contained" disabled={deleting}>
              Да, удалить
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="outlined" disabled={deleting}>
              Нет, оставить
            </Button>
          </Grid>
        </Grid>
      }>
      <Typography>Вы действительно хотите удалить ссылку?</Typography>
    </Modal>
  );
};
