import { useNotificationsControllerCreateMutation } from '@api/admin/adminGeneratedApi';
import { PushFormData } from '@area/notificationsPage/validation/validationSchemaPush';
import { Grid } from '@mui/material';
import { Routes } from '@navigation/routes';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Header } from '@layouts/Base/components/Header';
import { EditPushForm } from '@area/notificationsPage/components/EditPushForm';
import { usePageTitle } from '@hooks/usePageTitle';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router';
import indents from '@styles/indents.module.scss';

export const NewPushPage = () => {
  usePageTitle('Новая push-рассылка');
  const navigate = useNavigate();

  const defaultValues = {
    targets: '',
    text: '',
  };

  const [createNotification, { isLoading }] = useNotificationsControllerCreateMutation();

  const onCreatePush: SubmitHandler<PushFormData> = async (formData) => {
    await createNotification({
      notificationsRequest: {
        type: 3,
        sendAt: undefined,
        title: formData.title,
        text: formData.text,
        targets: formData.targets.split(','),
        link: formData.link,
      },
    });
    navigate(buildRoutePath(Routes.notifications, { absolute: true }));
  };

  return (
    <Grid container flexDirection="column" gap={indents.m}>
      <Grid item>
        <Header
          title="Новая push-рассылка"
          backBtnTitle="Назад к списку рассылок"
          backBtnRoute={buildRoutePath(Routes.notifications, { absolute: true })}
        />
      </Grid>
      <Grid item>
        <EditPushForm onSubmit={onCreatePush} defaultValues={defaultValues} isLoading={isLoading} isNew />
      </Grid>
    </Grid>
  );
};
