import { FC, SVGProps } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { wysiwygIcons } from '@components/Icon/icons/wysiwyg';
import { ReactComponent as ArticleIcon } from './icons/article.svg';
import { ReactComponent as BlackBoard } from './icons/black-board.svg';
import { ReactComponent as BookIcon } from './icons/book.svg';
import { ReactComponent as BubbleIcon } from './icons/bubble.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as CarnivalIcon } from './icons/carnival.svg';
import { ReactComponent as ClearFilterIcon } from './icons/clear-filter.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as CheckIcon } from './icons/check.svg';
import { ReactComponent as ChevronIcon } from './icons/chevron.svg';
import { ReactComponent as CheckboxCheckedIcon } from './icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from './icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from './icons/checkbox-indeterminate.svg';
import { ReactComponent as CoinIcon } from './icons/coin.svg';
import { ReactComponent as CommentIcon } from './icons/comment.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { ReactComponent as DesktopIcon } from './icons/desktop.svg';
import { ReactComponent as EditIcon } from './icons/edit.svg';
import { ReactComponent as EmailIcon } from './icons/email.svg';
import { ReactComponent as EducationIcon } from './icons/education.svg';
import { ReactComponent as HandshakeIcon } from './icons/handshake.svg';
import { ReactComponent as KeyHoleIcon } from './icons/key-hole.svg';
import { ReactComponent as KeyIcon } from './icons/key.svg';
import { ReactComponent as LinkIcon } from './icons/link.svg';
import { ReactComponent as MobileIcon } from './icons/mobile.svg';
import { ReactComponent as MoreIcon } from './icons/more.svg';
import { ReactComponent as NewsIcon } from './icons/news.svg';
import { ReactComponent as NotificationIcon } from './icons/notification.svg';
import { ReactComponent as PersonsIcon } from './icons/persons.svg';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as PhotoIcon } from './icons/photo.svg';
import { ReactComponent as RadioCheckedIcon } from './icons/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from './icons/radio-unchecked.svg';
import { ReactComponent as RefreshIcon } from './icons/refresh.svg';
import { ReactComponent as RequestIcon } from './icons/request.svg';
import { ReactComponent as RubleIcon } from './icons/ruble.svg';
import { ReactComponent as RubricIcon } from './icons/rubric.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as SendIcon } from './icons/send.svg';
import { ReactComponent as SettingsIcon } from './icons/settings.svg';
import { ReactComponent as SignoutIcon } from './icons/signout.svg';
import { ReactComponent as StopIcon } from './icons/stop.svg';
import { ReactComponent as SuccessIcon } from './icons/success.svg';
import { ReactComponent as TypeIcon } from './icons/type.svg';
import { ReactComponent as TypographIcon } from './icons/typograph.svg';
import { ReactComponent as UserIcon } from './icons/user.svg';
import { ReactComponent as LogoLongIcon } from './icons/logo-long.svg';
import { ReactComponent as LogoShortIcon } from './icons/logo-short.svg';
import { ReactComponent as LogoIconIcon } from './icons/logo-icon.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevronDown.svg';
import { ReactComponent as ChevronUpIcon } from './icons/chevronUp.svg';
import { ReactComponent as ChevronLeftIcon } from './icons/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from './icons/chevronRight.svg';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import { ReactComponent as EyeIcon } from './icons/eye.svg';
import { ReactComponent as EyeOffIcon } from './icons/eye-off.svg';
import { ReactComponent as FilterAddIcon } from './icons/filter-add.svg';
import { ReactComponent as NotePlusIcon } from './icons/note-plus.svg';

import { Icons } from './types';

const icons: Icons = {
  article: ArticleIcon,
  blackBoard: BlackBoard,
  book: BookIcon,
  bubble: BubbleIcon,
  calendar: CalendarIcon,
  carnival: CarnivalIcon,
  clearFilter: ClearFilterIcon,
  close: CloseIcon,
  check: CheckIcon,
  chevron: ChevronIcon,
  checkboxChecked: CheckboxCheckedIcon,
  checkboxUnchecked: CheckboxUncheckedIcon,
  checkboxIndeterminate: CheckboxIndeterminateIcon,
  coin: CoinIcon,
  comment: CommentIcon,
  delete: DeleteIcon,
  desktop: DesktopIcon,
  edit: EditIcon,
  email: EmailIcon,
  education: EducationIcon,
  handshake: HandshakeIcon,
  keyHole: KeyHoleIcon,
  key: KeyIcon,
  link: LinkIcon,
  mobile: MobileIcon,
  more: MoreIcon,
  news: NewsIcon,
  notification: NotificationIcon,
  persons: PersonsIcon,
  play: PlayIcon,
  plus: PlusIcon,
  photo: PhotoIcon,
  radioChecked: RadioCheckedIcon,
  radioUnchecked: RadioUncheckedIcon,
  refresh: RefreshIcon,
  request: RequestIcon,
  ruble: RubleIcon,
  rubric: RubricIcon,
  search: SearchIcon,
  send: SendIcon,
  settings: SettingsIcon,
  signout: SignoutIcon,
  stop: StopIcon,
  success: SuccessIcon,
  type: TypeIcon,
  typograph: TypographIcon,
  user: UserIcon,
  logoLong: LogoLongIcon,
  logoShort: LogoShortIcon,
  logoIcon: LogoIconIcon,
  chevronDown: ChevronDownIcon,
  chevronUp: ChevronUpIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  filter: FilterIcon,
  info: InfoIcon,
  download: DownloadIcon,
  eye: EyeIcon,
  eyeOff: EyeOffIcon,
  filterAdd: FilterAddIcon,
  notePlus: NotePlusIcon,
  ...wysiwygIcons,
};

interface IconProps extends Omit<SvgIconProps, 'color'> {
  name: keyof Icons;
  size?: number;
  color?: string; // цвета из палитры
}

export const Icon: FC<IconProps> = ({ name, sx, size, color, ...rest }) => {
  const icon: FC<SVGProps<SVGSVGElement>> = icons[name];

  return <SvgIcon component={icon} {...rest} sx={{ ...sx, fontSize: size, color }} />;
};
