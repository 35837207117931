import * as yup from 'yup';
import { EditModalFormData } from '@area/footerLinks/components/EditModal/types';
import { MediaFileParams } from '@area/publications/defaultValues';
import { ValidationError } from 'yup';

const MAX_ICON_SIZE = 1024 * 1024;
const ALLOWED_ICON_MIMETYPES = ['image/svg+xml', 'image/png', 'image/jpeg'];

export const validationSchema = yup.object<EditModalFormData>({
  title: yup.string().required('Обязательное поле'),
  url: yup.string().required('Обязательное поле').url('Введите корректный URL'),
  icon: yup.mixed().test('file', (value) => {
    const { file } = (value as MediaFileParams) ?? {};

    if (!ALLOWED_ICON_MIMETYPES.includes(file.type)) {
      return new ValidationError(
        'Неверные формат файла. Поддерживаемые форматы .svg, .jpeg, .jpg, .png',
        value,
        'icon',
      );
    }
    if (file.size > MAX_ICON_SIZE) {
      return new ValidationError('Максимальный размер файла иконки 1МБ', value, 'icon');
    }

    return true;
  }),
  order: yup.number().min(1, 'Число порядка должно быть не меньше 1'),
});
