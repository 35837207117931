import { FC, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Modal } from '@components/Modal';
import { FooterLinkResponse, useFilesControllerDeleteFileMutation } from '@api/admin/adminGeneratedApi';
import indents from '@styles/indents.module.scss';
import { FormField } from '@components/FormField';
import { useNotifications } from '@area/notifications/hooks/useNotifications';
import { useFooterLinksControllerCreateMutation, useFooterLinksControllerUpdateMutation } from '@api/admin/adminApi';
import { useYupValidationResolver } from '@hooks/useYupValidationResolver';
import { validationSchema } from '@area/footerLinks/components/EditModal/validation';
import { useUploadMediaFiles } from '@hooks/useUploadMediaFiles';
import { EditModalProps, EditModalFormData } from './types';

export const EditModal: FC<EditModalProps> = ({ modalRef, refetch }) => {
  const { handlePushItem } = useNotifications();
  const [open, setOpen] = useState(false);
  const [create] = useFooterLinksControllerCreateMutation();
  const [update] = useFooterLinksControllerUpdateMutation();
  const [deleteFile, { isLoading: isIconDeleting }] = useFilesControllerDeleteFileMutation();
  const { uploadMediaFiles } = useUploadMediaFiles();
  const form = useForm<EditModalFormData>({
    defaultValues: { id: null, title: '', url: '', order: '1' as any, icon: undefined },
    resolver: useYupValidationResolver(validationSchema),
  });

  const id = form.watch('id');
  const icon = form.watch('icon');

  useImperativeHandle(modalRef, () => ({
    open: (link?: FooterLinkResponse) => {
      setOpen(true);
      form.reset({
        title: link?.title ?? '',
        url: link?.url ?? '',
        order: link?.order ?? 1,
        id: link?.id ?? null,
        icon: link?.icon,
      });
    },
  }));

  const onClose = () => {
    setOpen(false);
    form.reset(form.getValues());
  };
  const onDeleteFile = async () => {
    const icon = form.getValues('icon');
    if (icon?.id) {
      await deleteFile({ fileId: icon?.id });
    }
    form.setValue('icon', undefined as any);
  };

  const onSubmit = form.handleSubmit(async () => {
    const { id, icon, ...data } = form.getValues();

    try {
      let subjectId = id;

      if (!id) {
        const newLink = await create({ footerLinkCreatorDto: data }).unwrap();
        subjectId = newLink.id;
      } else {
        await update({ id, footerLinkUpdaterDto: data }).unwrap();
      }

      if (subjectId && icon) {
        await uploadMediaFiles({ subjectId, mainImage: icon });
        await refetch();
      }

      handlePushItem({ severity: 'success', text: `Ссылка успешно ${id ? 'обновлена' : 'создана'}` });
      onClose();
    } catch (e) {
      handlePushItem({ severity: 'error', text: `Не удалось ${id ? 'обновить' : 'создать'} ссылку` });
    }
  });

  return (
    <Modal
      open={open}
      title={id ? 'Изменение подвальной ссылки' : 'Создание подвальной ссылки'}
      maxWidth="sm"
      onClose={onClose}
      actions={
        <Stack direction="row" gap={indents.s}>
          <Button variant="outlined" onClick={onClose} disabled={form.formState.isSubmitting}>
            Отмена
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={form.formState.isSubmitting || !form.formState.isDirty}>
            Сохранить
          </Button>
        </Stack>
      }>
      <FormProvider {...form}>
        <Stack gap={indents.m}>
          <FormField<EditModalFormData> name="title" placeholder="Введите название" title="Название" fullWidth />
          <FormField<EditModalFormData> name="url" placeholder="Введите ссылку" title="URL" fullWidth />
          <FormField<EditModalFormData>
            title="Иконка"
            onDelete={onDeleteFile}
            imageUrl={icon?.url}
            name="icon.file"
            type="imagePartner"
            loadingText="Удаление иконки"
            loading={isIconDeleting}
            error={form.formState?.errors?.icon as any}
          />
          <FormField<EditModalFormData>
            name="order"
            type="number"
            placeholder="Введите номер порядка"
            title="Порядок"
            fullWidth
          />
        </Stack>
      </FormProvider>
    </Modal>
  );
};
